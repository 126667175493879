import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import theme from "./theme/theme";
import "./styles/style.css";
import HamburgerMenu from "./components/HamburgerMenu";
import Navbar from "./components/Navbar";
import { LteTablet } from "./utils/MediaQueries";
import Prime from "./components/Prime";
import Base from "./components/Base";
import Fold from "./components/Fold";
import TopCollections from "./components/TopCollections";
import Features from "./components/Features";
import Partners from "./components/Partners";
import Subscribe from "./components/Subscribe";
import Footer from "./components/Footer";
import UseCases from "./components/UseCases";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Unsubscribe from "./components/Unsubscribe";
import NotFound404 from "./components/NotFound404";
import reveal from "./functions/reveal";

window.addEventListener("scroll", reveal);

const routing = (
  <Router>
    <Routes>
      <Route
        path="/"
        element={
          <ThemeProvider theme={theme}>
            <Fold />
            <Base id="outer-container">
              <LteTablet>
                <HamburgerMenu
                  pageWrapId={"page-wrap"}
                  outerContainerId={"outer-container"}
                />
              </LteTablet>
              <main id="page-wrap">
                <Navbar />
                <Prime />
                <TopCollections />
                <UseCases />
                <Features />
                <Partners />
                <Subscribe />
                <Footer />
              </main>
            </Base>
          </ThemeProvider>
        }
      />
      <Route
        path="/unsubscribe"
        element={
          <ThemeProvider theme={theme}>
            <Fold />
            <Unsubscribe />
          </ThemeProvider>
        }
      />
      <Route
        path="*"
        element={
          <ThemeProvider theme={theme}>
            <Fold />
            <NotFound404 />
          </ThemeProvider>
        }
      />
    </Routes>
  </Router>
);

ReactDOM.render(routing, document.getElementById("root"));
