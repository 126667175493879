import { IconButton, InputAdornment, TextField } from "@mui/material";
import styled from "styled-components";
import SectionHeader from "./SectionHeader";
import SectionWrapper from "./SectionWrapper";
import { Mobile, TabletDesktop } from "../utils/MediaQueries";
import Button from "./Button/Button";
import { useRef, useState } from "react";
import { addDoc, collection, where, query, getDocs } from "firebase/firestore";
import db from "../utils/Firebase";
import { getAnalytics, logEvent } from "firebase/analytics";
import emailjs from "@emailjs/browser";
import { ProgressLoadingV2 } from "./ProgressLoading";

const SubscribeDescription = styled.p`
  font-family: ${(props) => props.theme.fonts.body};
  font-size: ${(props) => props.theme.fontSizeElements.body2}px;
  margin-bottom: 30px;
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    margin: 20px auto;
    width: 80%;
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    font-size: ${(props) => props.theme.fontSizeElements.h1phone}px;
    margin-bottom: 30px;
  }
`;

const SearchBox = styled(TextField)(() => ({
  width: "100%",
  background: "#D9D9D9",
  borderRadius: "40px",
  border: "none",
  "& input": {
    paddingLeft: "30px",
  },
  "& fieldset": {
    borderRadius: "30px",
  },
}));

const SearchBoxDiv = styled.div`
  width: 40%;
  min-width: 500px;
  max-width: 800px;
  margin: 0 auto;
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    width: 70%;
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    width: 90%;
    min-width: 250px;
  }
`;

const ProgresBarDiv = styled.div`
  padding-bottom: 2rem;
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    padding-bottom: 2rem;
  }
`;

interface Email {
  email: string;
}

function Subscribe() {
  const form = useRef<any>();
  const [formShow, setFormShow] = useState(true);
  const analytics = getAnalytics();
  const [loader, setLoader] = useState(false);

  const subscribeEmail = async (event: any) => {
    event.preventDefault();
    setLoader(true);
    //setFormShow(false);

    var email: Email = { email: event.target[0].value };

    const emailsRef = collection(db, "Emails");

    const q = query(
      collection(db, "Emails"),
      where("email", "==", event.target[0].value)
    );
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      setFormShow(false);
      setLoader(false);
      return;
    }
    //console.log("Tady už se nedostane!!");

    addDoc(emailsRef, email)
      .then((response) => {
        //console.log(response);
        setFormShow(false);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error.message);
        setLoader(false);
      });

    emailjs
      .sendForm(
        "service_0hpofg5",
        "template_l3fvm96",
        form.current,
        "cc827ulnQCRy7O6Ca"
      )
      .then(
        (result) => {
          console.log(result.text);
          setFormShow(false);
          setLoader(false);
        },
        (error) => {
          console.log(error.text);
          setLoader(false);
        }
      );

    logEvent(analytics, "subscribed");
  };

  return (
    <SectionWrapper style={{ marginBottom: "-70px" }} id="join-us">
      <SectionHeader sectionText="Get More Updates" />
      <SubscribeDescription className="reveal">
        Join our mailing list to stay in the loop with our newest feature
        releases, new collections and tips and tricks
      </SubscribeDescription>

      <TabletDesktop>
        <>
          <ProgresBarDiv>
            {loader && <ProgressLoadingV2 stroke={6} size={60} />}
          </ProgresBarDiv>
          {formShow ? (
            <SearchBoxDiv className="reveal">
              <form
                ref={form}
                onSubmit={subscribeEmail}
                hidden={loader ? true : false}
              >
                <SearchBox
                  style={{ margin: "0 auto 50px" }}
                  placeholder="Your email..."
                  name="user_email"
                  type="email"
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" color="primary">
                          <Button type="submit" size="large">
                            I'm in
                          </Button>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                ></SearchBox>
                <SearchBox
                  name="unsub_link"
                  type="hidden"
                  value={window.location + "/unsubscribe"}
                ></SearchBox>
              </form>
            </SearchBoxDiv>
          ) : (
            <SubscribeDescription>
              Thank you for joining our community!
            </SubscribeDescription>
          )}
        </>
      </TabletDesktop>
      <Mobile>
        <>
          {loader && (
            <ProgresBarDiv>
              <ProgressLoadingV2 stroke={6} size={40} />{" "}
            </ProgresBarDiv>
          )}
          {formShow ? (
            <SearchBoxDiv className="reveal">
              <form
                ref={form}
                onSubmit={subscribeEmail}
                hidden={loader ? true : false}
              >
                <SearchBox
                  size="small"
                  style={{ margin: "0 auto 50px", fontSize: "5" }}
                  placeholder="Your email..."
                  name="user_email"
                  type="email"
                  required
                  InputProps={{
                    style: { fontSize: 12 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" color="primary">
                          <Button type="submit" size="small">
                            I'm in
                          </Button>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                ></SearchBox>
                <SearchBox
                  name="unsub_link"
                  type="hidden"
                  value={window.location + "/unsubscribe"}
                ></SearchBox>
              </form>
            </SearchBoxDiv>
          ) : (
            <SubscribeDescription>
              Thank you for joining our community!
            </SubscribeDescription>
          )}
        </>
      </Mobile>
    </SectionWrapper>
  );
}

export default Subscribe;
