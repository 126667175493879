import React from "react";
import { Wrapper, Link, Label } from "./button.styled";

interface ButtonInterface {
  children: JSX.Element | Array<JSX.Element> | string;
  href?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  id?: string;
  button?: string;
  size?: "small" | "medium" | "large";
  variant?: "filled" | "outlined" | "transparent";
  color?: "primary" | "secondary";
  block?: boolean;
  disabled?: boolean;
  gradient?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  target?: "_blank";
}

const Button: React.FC<ButtonInterface> = ({
  href,
  children,
  id,
  color = "primary",
  size = "medium",
  variant = "filled",
  disabled,
  onClick,
  block,
  type = "submit",
  gradient = true,
  target,
}) => {
  const ButtonStyled = (
    <Wrapper
      disabled={disabled}
      id={id}
      onClick={onClick}
      block={block}
      color={color}
      type={type}
      gradient={gradient}
      variant={variant}
    >
      {children && <Label size={size}>{children}</Label>}
    </Wrapper>
  );

  if (!href) {
    return ButtonStyled;
  }

  return (
    <Link href={href} target={target}>
      {ButtonStyled}
    </Link>
  );
};

export default Button;
