import styled, { css } from "styled-components";
import SectionWrapper from "./SectionWrapper";
import Logo from "./Logo";
import Discord from "../icons/Discord";
import Twitter from "../icons/Twitter";
import GitHub from "../icons/GitHub";
import Medium from "../icons/Medium";
import LinkedIn from "../icons/LinkedIn";
import { getAnalytics, logEvent } from "firebase/analytics";
import React from "react";

const FooterWrapper = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.fonts.description};
    font-size: ${theme.fontSizeElements.body2}px;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto 30px;
  `}
`;

const FooterInner = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    border-top: 1px solid white;
    padding: 30px 0;

    @media screen and (${theme.breakpoints.gteTabletQuery}) {
      margin: 0 20px;
    }

    @media screen and (${theme.breakpoints.lteTabletQuery}) {
      flex-direction: column;
      align-items: center;
      padding: 30px 0 0;
    }
  `}
`;

const Social = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 5px;

    @media screen and (${theme.breakpoints.lteTabletQuery}) {
      margin: 25px 0 15px;
    }
  `}
`;

const Link = styled.a`
  ${({ theme }) => css`
    display: inline-block;
    background: url("images/social/bg.svg") no-repeat center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;

    svg {
      fill: ${theme.colors.text};
    }

    &:hover {
      svg {
        fill: ${theme.colors.danger};
      }
    }

    @media screen and (${theme.breakpoints.lteTabletQuery}) {
      height: 40px;
      width: 40px;

      svg {
        height: 0.9em;
        width: 0.9em;
      }
    }
  `}
`;

const Copy = styled.text`
  ${({ theme }) => css`
    font-family: ${theme.fonts.description};
    font-size: ${theme.fontSizeElements.h1phone}px;
    margin-bottom: 50px;

    @media screen and (${theme.breakpoints.lteTabletQuery}) {
      font-size: ${theme.fontSizeElements.h2phone}px;
    }
    @media screen and (${theme.breakpoints.mobileQuery}) {
      font-size: ${theme.fontSizeElements.h3phone}px;
    }
  `}
`;

const Footer: React.FC = () => {
  const analytics = getAnalytics();

  function logSocialsClick(media: String) {
    logEvent(analytics, `[Footer]Socials: ${media}`);
  }

  return (
    <SectionWrapper>
      <FooterWrapper>
        <FooterInner>
          <Logo footer />
          <Social>
            <Link
              href="https://discord.com/invite/ZPEvPDWhXT"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => logSocialsClick("discord")}
            >
              <Discord />
            </Link>
            <Link
              href="https://twitter.com/dobbyprotocol"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => logSocialsClick("discord")}
            >
              <Twitter />
            </Link>
            <Link
              href="https://medium.com/@dobbyprotocol"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => logSocialsClick("medium")}
            >
              <Medium />
            </Link>
            <Link
              href="https://www.linkedin.com/company/dobby-labs/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => logSocialsClick("linkedIn")}
            >
              <LinkedIn />
            </Link>
            <Link
              href="https://github.com/DOBBYLABS"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => logSocialsClick("github")}
            >
              <GitHub />
            </Link>
          </Social>
        </FooterInner>
        <Copy>© 2022 DOBBY Protocol. All Rights Reserved</Copy>
      </FooterWrapper>
    </SectionWrapper>
  );
};

export default Footer;
