import styled, { keyframes } from "styled-components";

interface ProgressLoadingInterface {
  stroke: number;
  size: number;
}

const draw = keyframes`
0% {
  d: path('M67.3129 111.589L92.3544 99.0273C98.557 95.9158 103.023');
}

7.14% {
  d: path('M 67.3129 111.589 L 92.3544 99.0273 C 98.557 95.9158 103.023 90.1702 104.509 83.3918');
}

14.28% {
  d: path('M 67.3129 111.589 L 92.3544 99.0273 C 98.557 95.9158 103.023 90.1702 104.509 83.3918 L 111.058 53.4989 C 112.507 46.8859 110.969 39.9703 106.854 34.5948');
}

21.42% {
  d: path('M 67.3129 111.589 L 92.3544 99.0273 C 98.557 95.9158 103.023 90.1702 104.509 83.3918 L 111.058 53.4989 C 112.507 46.8859 110.969 39.9703 106.854 34.5948 L 88.9498 11.2081 C 84.5977 5.5233 77.8466 2.1894 70.6872 2.1894'); 
}

28.56% {
  d: path('M 67.3129 111.589 L 92.3544 99.0273 C 98.557 95.9158 103.023 90.1702 104.509 83.3918 L 111.058 53.4989 C 112.507 46.8859 110.969 39.9703 106.854 34.5948 L 88.9498 11.2081 C 84.5977 5.5233 77.8466 2.1894 70.6872 2.1894 L 43.3128 2.1894 C 36.1534 2.1894 29.4023 5.5233 25.0502 11.2081'); 
}

35.70% {
  d: path('M 67.3129 111.589 L 92.3544 99.0273 C 98.557 95.9158 103.023 90.1702 104.509 83.3918 L 111.058 53.4989 C 112.507 46.8859 110.969 39.9703 106.854 34.5948 L 88.9498 11.2081 C 84.5977 5.5233 77.8466 2.1894 70.6872 2.1894 L 43.3128 2.1894 C 36.1534 2.1894 29.4023 5.5233 25.0502 11.2081 L 7.146 34.5948 C 3.0306 39.9703 1.4925 46.8859 2.9415 53.4989'); 
}

42.84% {
  d: path('M 67.3129 111.589 L 92.3544 99.0273 C 98.557 95.9158 103.023 90.1702 104.509 83.3918 L 111.058 53.4989 C 112.507 46.8859 110.969 39.9703 106.854 34.5948 L 88.9498 11.2081 C 84.5977 5.5233 77.8466 2.1894 70.6872 2.1894 L 43.3128 2.1894 C 36.1534 2.1894 29.4023 5.5233 25.0502 11.2081 L 7.146 34.5948 C 3.0306 39.9703 1.4925 46.8859 2.9415 53.4989 L 9.4915 83.3918 C 10.9767 90.1702 15.443 95.9158 21.6456 99.0273'); 
}

49.98% {
  d: path('M 67.3129 111.589 L 92.3544 99.0273 C 98.557 95.9158 103.023 90.1702 104.509 83.3918 L 111.058 53.4989 C 112.507 46.8859 110.969 39.9703 106.854 34.5948 L 88.9498 11.2081 C 84.5977 5.5233 77.8466 2.1894 70.6872 2.1894 L 43.3128 2.1894 C 36.1534 2.1894 29.4023 5.5233 25.0502 11.2081 L 7.146 34.5948 C 3.0306 39.9703 1.4925 46.8859 2.9415 53.4989 L 9.4915 83.3918 C 10.9767 90.1702 15.443 95.9158 21.6456 99.0273 L 46.6871 111.589 C 53.177 114.845 60.823 114.845 67.3129 111.589'); 
}

57.12% {
  d: path('M 104.509 83.3918 L 111.058 53.4989 C 112.507 46.8859 110.969 39.9703 106.854 34.5948 L 88.9498 11.2081 C 84.5977 5.5233 77.8466 2.1894 70.6872 2.1894 L 43.3128 2.1894 C 36.1534 2.1894 29.4023 5.5233 25.0502 11.2081 L 7.146 34.5948 C 3.0306 39.9703 1.4925 46.8859 2.9415 53.4989 L 9.4915 83.3918 C 10.9767 90.1702 15.443 95.9158 21.6456 99.0273 L 46.6871 111.589 C 53.177 114.845 60.823 114.845 67.3129 111.589'); 
}

64.26% {
  d: path('M 106.854 34.5948 L 88.9498 11.2081 C 84.5977 5.5233 77.8466 2.1894 70.6872 2.1894 L 43.3128 2.1894 C 36.1534 2.1894 29.4023 5.5233 25.0502 11.2081 L 7.146 34.5948 C 3.0306 39.9703 1.4925 46.8859 2.9415 53.4989 L 9.4915 83.3918 C 10.9767 90.1702 15.443 95.9158 21.6456 99.0273 L 46.6871 111.589 C 53.177 114.845 60.823 114.845 67.3129 111.589'); 
}

71.40% {
  d: path('M 70.6872 2.1894 L 43.3128 2.1894 C 36.1534 2.1894 29.4023 5.5233 25.0502 11.2081 L 7.146 34.5948 C 3.0306 39.9703 1.4925 46.8859 2.9415 53.4989 L 9.4915 83.3918 C 10.9767 90.1702 15.443 95.9158 21.6456 99.0273 L 46.6871 111.589 C 53.177 114.845 60.823 114.845 67.3129 111.589'); 
}

78.54% {
  d: path('M 25.0502 11.2081 L 7.146 34.5948 C 3.0306 39.9703 1.4925 46.8859 2.9415 53.4989 L 9.4915 83.3918 C 10.9767 90.1702 15.443 95.9158 21.6456 99.0273 L 46.6871 111.589 C 53.177 114.845 60.823 114.845 67.3129 111.589'); 
}

85.68% {
  d: path('M 2.9415 53.4989 L 9.4915 83.3918 C 10.9767 90.1702 15.443 95.9158 21.6456 99.0273 L 46.6871 111.589 C 53.177 114.845 60.823 114.845 67.3129 111.589'); 
}

92.82% {
  d: path('M 21.6456 99.0273 L 46.6871 111.589 C 53.177 114.845 60.823 114.845 67.3129 111.589'); 
}

100% {
  d: path('');
}
`;

const Svg = styled.svg`
  stroke-dasharray: 348.0303955078125;
  stroke-dashoffset: 0;
  animation: ${draw} 2s infinite;
  //animation: ${draw} 2s linear infinite;
  path {
    animation-name: ${draw};
    animation-duration: 2.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
  }
`;

export const ProgressLoading = ({ stroke, size }: ProgressLoadingInterface) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 114 117" fill="none">
      {" "}
      <path
        // d="M67.3129 111.589L92.3544 99.0273C98.557 95.9158 103.023 90.1702 104.509 83.3918L111.058 53.4989C112.507 46.8859 110.969 39.9703 106.854 34.5948L88.9498 11.2081C84.5977 5.52335 77.8466 2.18945 70.6872 2.18945L43.3128 2.18945C36.1534 2.18945 29.4023 5.52335 25.0502 11.2081L7.14596 34.5948C3.0306 39.9703 1.49252 46.8859 2.94154 53.4989L9.49147 83.3918C10.9767 90.1702 15.443 95.9158 21.6456 99.0273L46.6871 111.589C53.177 114.845 60.823 114.845 67.3129 111.589Z"
        d=""
        stroke="url(#paint0_linear_1316_2165)"
        strokeWidth={stroke}
      />{" "}
      <defs>
        {" "}
        <linearGradient
          id="paint0_linear_1316_2165"
          x1="-6.1579"
          y1="-6"
          x2="77.7392"
          y2="-37.8041"
          gradientUnits="userSpaceOnUse"
        >
          {" "}
          <stop stopColor="#E64848" />{" "}
          <stop offset="0.208333" stopColor="#FF0000" />{" "}
          <stop offset="0.802083" stopColor="#FFB22C" />{" "}
          <stop offset="1" stopColor="#FFB22C" />{" "}
        </linearGradient>{" "}
      </defs>{" "}
    </Svg>
  );
};

const drawV2 = keyframes`
from {
  stroke-dashoffset: 696.060791016;
}
to {
  stroke-dashoffset: 0;
}
`;

const SvgV2 = styled.svg`
  stroke-dasharray: 348.0303955078125;
  stroke-dashoffset: 0;
  animation: ${drawV2} 2s infinite;
  // animation: ${drawV2} 2s linear infinite;
`;

export const ProgressLoadingV2 = ({
  stroke,
  size,
}: ProgressLoadingInterface) => {
  return (
    <SvgV2 width={size} height={size} viewBox="0 0 114 117" fill="none">
      {" "}
      <path
        d="M67.3129 111.589L92.3544 99.0273C98.557 95.9158 103.023 90.1702 104.509 83.3918L111.058 53.4989C112.507 46.8859 110.969 39.9703 106.854 34.5948L88.9498 11.2081C84.5977 5.52335 77.8466 2.18945 70.6872 2.18945L43.3128 2.18945C36.1534 2.18945 29.4023 5.52335 25.0502 11.2081L7.14596 34.5948C3.0306 39.9703 1.49252 46.8859 2.94154 53.4989L9.49147 83.3918C10.9767 90.1702 15.443 95.9158 21.6456 99.0273L46.6871 111.589C53.177 114.845 60.823 114.845 67.3129 111.589Z"
        stroke="url(#paint0_linear_1316_2165)"
        strokeWidth={stroke}
      />{" "}
      <defs>
        {" "}
        <linearGradient
          id="paint0_linear_1316_2165"
          x1="-6.1579"
          y1="-6"
          x2="77.7392"
          y2="-37.8041"
          gradientUnits="userSpaceOnUse"
        >
          {" "}
          <stop stopColor="#E64848" />{" "}
          <stop offset="0.208333" stopColor="#FF0000" />{" "}
          <stop offset="0.802083" stopColor="#FFB22C" />{" "}
          <stop offset="1" stopColor="#FFB22C" />{" "}
        </linearGradient>{" "}
      </defs>{" "}
    </SvgV2>
  );
};
