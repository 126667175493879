import React from "react";
import IconBase from "react-icon-base";

export default function Icon(props: any) {
  return (
    <IconBase
      {...props}
      viewBox={"0 0 53.57 43.54"}
      dangerouslySetInnerHTML={{
        __html: `<path d="M53.57,5.15a21.61,21.61,0,0,1-6.31,1.73A11,11,0,0,0,52.09.8a21.84,21.84,0,0,1-7,2.67A11,11,0,0,0,26.1,11a11.56,11.56,0,0,0,.28,2.51A31.21,31.21,0,0,1,3.73,2,10.89,10.89,0,0,0,2.24,7.54a11,11,0,0,0,4.89,9.15,11,11,0,0,1-5-1.38v.14A11,11,0,0,0,11,26.23a11.09,11.09,0,0,1-2.9.38A10.92,10.92,0,0,1,6,26.41a11,11,0,0,0,10.27,7.64,22.09,22.09,0,0,1-13.65,4.7A22.62,22.62,0,0,1,0,38.6a31.09,31.09,0,0,0,16.85,4.94c20.21,0,31.27-16.75,31.27-31.28,0-.47,0-.95,0-1.42A22.3,22.3,0,0,0,53.57,5.15Z"/>`,
      }}
    />
  );
}
