import { useState } from "react";
import { LteTablet } from "../utils/MediaQueries";
import Base from "./Base";
import Footer from "./Footer";
import HamburgerMenu from "./HamburgerMenu";
import Navbar from "./Navbar";
import PrimeUnsubscribe from "./PrimeUnsubscribe";
import UnsubForm from "./UnsubForm";

const Unsubscribe = () => {
  const [formShow, setFormShow] = useState(true);

  return (
    <Base id="outer-container">
      <LteTablet>
        <HamburgerMenu
          pageWrapId={"page-wrap"}
          outerContainerId={"outer-container"}
        />
      </LteTablet>
      <main id="page-wrap" style={{ minHeight: "100vh" }}>
        <Navbar />
        <PrimeUnsubscribe formShow={formShow} />
        <UnsubForm setFormShow={setFormShow} formShow={formShow} />
        <div style={{ margin: "auto" }}></div>
        <Footer />
      </main>
    </Base>
  );
};

export default Unsubscribe;
