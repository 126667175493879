import styled from "styled-components";

const PrimeWrapper = styled.div`
  position: relative;
  padding: 5rem 0 16rem;
  margin: 2rem auto -5rem;
  text-align: center;
  &:after {
    content: "";
    background-image: url(./images/background/polygonPrime.png);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.7;
    z-index: 0;
  }
  @media screen and (${(props) => props.theme.breakpoints.desktopQuery}) {
    margin: 2rem auto -10rem;
    padding-top: 2rem;
  }
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    padding: 5rem 0 10rem;
    margin-top: 2rem;
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    margin: 2rem auto;
    padding: 2rem 0 2rem;
    &:after {
    }
  }
`;

const PrimeHeader = styled.header`
  position: relative;
  z-index: 2;
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: ${(props) => props.theme.fontSizeElements.prime}px;
  font-weight: 500;
  margin: 2rem 10% 0;
  background-image: linear-gradient(
    to right,
    #fb0707 30%,
    #ffb22c 60%,
    #fff500 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow-wrap: break-word;
  @media screen and (${(props) => props.theme.breakpoints.desktopQuery}) {
    font-size: ${(props) => props.theme.fontSizeElements.primeTablet}px;
  }
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    font-size: ${(props) => props.theme.fontSizeElements.h1}px;
    margin: -20px 10% 0;
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    font-size: ${(props) => props.theme.fontSizeElements.h3}px;
    margin: -20px 10% 0;
  }
`;

const PrimeDescription = styled.div`
  position: relative;
  z-index: 2;
  width: 40%;
  margin: 2rem auto 2rem;
  font-family: ${(props) => props.theme.fonts.description};
  font-size: ${(props) => props.theme.fontSizeElements.h4}px;
  @media screen and (${(props) => props.theme.breakpoints.desktopQuery}) {
    width: 45%;
    margin: 2rem auto 2rem;
    font-size: ${(props) => props.theme.fontSizeElements.h5}px;
  }
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    width: 45%;
    margin: 1rem auto 2rem;
    font-size: ${(props) => props.theme.fontSizeElements.h6}px;
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    font-size: ${(props) => props.theme.fontSizeElements.h3phone}px;
    width: 50%;
    margin: 1rem auto;
  }
`;

const Buttons = styled.div`
  position: relative;
  z-index: 2;
`;

interface PrimeUnsubscribeInterface {
  formShow: boolean;
}

function PrimeUnsubscribe({ formShow }: PrimeUnsubscribeInterface) {
  return (
    <PrimeWrapper>
      {formShow ? (
        <>
          <PrimeHeader>We are sorry to see you go</PrimeHeader>
          <PrimeDescription>
            If you ever change your mind we are still here for you, DOBBY
            Protocol team
          </PrimeDescription>
        </>
      ) : (
        <>
          <PrimeHeader>Unsubscribe Successful</PrimeHeader>
          <PrimeDescription>
            We are sorry that you are leaving.
          </PrimeDescription>
          <PrimeDescription>
            You can always change your mind and get back to us!
          </PrimeDescription>
        </>
      )}
    </PrimeWrapper>
  );
}

export default PrimeUnsubscribe;
