import { useEffect, useState } from "react";
import styled from "styled-components";
import SectionHeader from "./SectionHeader";
import ReactCardFlip from "react-card-flip";
import { Mobile, TabletDesktop } from "../utils/MediaQueries";
import { getAnalytics, logEvent } from "firebase/analytics";

const Wrapper = styled.div`
  position: relative;
  text-align: center;
  margin: 100px auto 100px;
  max-width: 1700px;
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    width: 90%;
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    margin: 30px auto;
    width: 90%;
  }
`;

const UCWrapperTabletDesktop = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 70%;
  justify-content: space-between;
  margin: 80px auto 200px !important;
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    width: 80%;
    margin: 0 auto 150px;
  }
`;

const UCWrapperMobile = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  justify-content: space-between;
  margin: 60px auto 200px;
`;

const UCItem = styled.img`
  width: 110%;
  max-width: 450px;
  -moz-backface-visibility: hidden;
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    width: 120%;
  }
`;

const UCItemFlipped = styled.img`
  width: 110%;
  max-width: 450px;
  transform: scale(1.2);
  -moz-backface-visibility: hidden;
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    width: 120%;
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    transform: scale(1.1);
  }
`;

const UCTabletDesktop = styled.div`
  width: 30%;
`;

const UCMobile = styled.div`
  width: 60%;
`;

function UseCases() {
  const analytics = getAnalytics();
  const [visited, setVisited] = useState(false);

  const [isFlipped, setIsFlipped] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  });

  const handleFlip = (id: number) => {
    if (!visited) {
      setIsFlipped((prevState) => ({
        ...prevState,
        1: !prevState[1],
      }));
      setVisited(true);
    }

    type ObjectKey = keyof typeof isFlipped;
    const item = id as ObjectKey;
    logEvent(analytics, `Use-case id: ${id} show/hide: ${!isFlipped[item]}`);
    setIsFlipped((prevState) => ({
      ...prevState,
      [item]: !prevState[item],
    }));
  };

  const [flipped, setFlipped] = useState(false);

  const flipMe = () => {
    let flip = document.querySelector(".flip");

    if (flip) {
      let windowHeight = window.innerHeight;
      let elementTop = flip.getBoundingClientRect().top;
      let elementVisible = 50;

      if (elementTop < windowHeight - elementVisible) {
        setFlipped(true);
      }
    }
  };

  useEffect(() => {
    if (flipped) {
      setIsFlipped((prevState) => ({
        ...prevState,
        1: true,
      }));
    }
  }, [flipped]);

  !flipped && window.addEventListener("scroll", flipMe);

  return (
    <Wrapper id="usecases">
      <SectionHeader sectionText="Use Cases" />
      <TabletDesktop>
        <UCWrapperTabletDesktop>
          <UCTabletDesktop>
            <div
              onMouseEnter={() => handleFlip(1)}
              onMouseLeave={() => handleFlip(1)}
              className="reveal reveal-0 flip"
            >
              <ReactCardFlip isFlipped={isFlipped[1]} flipDirection="vertical">
                <UCItem
                  src="images/usecases/uc01_name.png"
                  alt="Gaming polygon"
                />
                <UCItemFlipped
                  src="images/usecases/uc01_des.png"
                  alt="Gaming polygon description"
                />
              </ReactCardFlip>
            </div>
          </UCTabletDesktop>

          <UCTabletDesktop
            style={{
              position: "relative",
              marginTop: "3rem",
            }}
          >
            <div
              onMouseEnter={() => handleFlip(2)}
              onMouseLeave={() => handleFlip(2)}
              className="reveal reveal-1"
            >
              <ReactCardFlip isFlipped={isFlipped[2]} flipDirection="vertical">
                <UCItem
                  src="images/usecases/uc02_name.png"
                  alt="Delegation polygon"
                />
                <UCItemFlipped
                  src="images/usecases/uc02_des.png"
                  alt="Delegation polygon description"
                />
              </ReactCardFlip>
            </div>
          </UCTabletDesktop>

          <UCTabletDesktop
            style={{
              position: "relative",
              marginTop: "-3rem",
            }}
          >
            <div
              onMouseEnter={() => handleFlip(3)}
              onMouseLeave={() => handleFlip(3)}
              className="reveal reveal-2"
            >
              <ReactCardFlip isFlipped={isFlipped[3]} flipDirection="vertical">
                <UCItem src="images/usecases/uc03_name.png" alt="VIP polygon" />
                <UCItemFlipped
                  src="images/usecases/uc03_des.png"
                  alt="VIP polygon description"
                />
              </ReactCardFlip>
            </div>
          </UCTabletDesktop>

          <UCTabletDesktop
            style={{
              position: "relative",
              marginLeft: "25%",
              marginTop: "2rem",
            }}
          >
            <div
              onMouseEnter={() => handleFlip(4)}
              onMouseLeave={() => handleFlip(4)}
              className="reveal reveal-0"
            >
              <ReactCardFlip isFlipped={isFlipped[4]} flipDirection="vertical">
                <UCItem
                  src="images/usecases/uc04_name.png"
                  alt="Guild polygon"
                />
                <UCItemFlipped
                  src="images/usecases/uc04_des.png"
                  alt="Guild polygon description"
                />
              </ReactCardFlip>
            </div>
          </UCTabletDesktop>

          <UCTabletDesktop
            style={{
              position: "relative",
              marginRight: "10%",
              marginTop: "10rem",
            }}
          >
            <div
              onMouseEnter={() => handleFlip(5)}
              onMouseLeave={() => handleFlip(5)}
              className="reveal reveal-1"
            >
              <ReactCardFlip isFlipped={isFlipped[5]} flipDirection="vertical">
                <UCItem
                  src="images/usecases/uc05_name.png"
                  alt="Discord polygon"
                />
                <UCItemFlipped
                  src="images/usecases/uc05_des.png"
                  alt="Discord polygon description"
                />
              </ReactCardFlip>
            </div>
          </UCTabletDesktop>
        </UCWrapperTabletDesktop>
      </TabletDesktop>

      <Mobile>
        <UCWrapperMobile>
          <UCMobile
            style={{
              position: "relative",
              top: "rem",
            }}
          >
            <ReactCardFlip isFlipped={isFlipped[1]} flipDirection="vertical">
              <UCItem
                src="images/usecases/uc01_name.png"
                alt="Gaming polygon"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  handleFlip(1);
                }}
                className="reveal"
              />
              <UCItemFlipped
                src="images/usecases/uc01_des.png"
                alt="Gaming polygon description"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  handleFlip(1);
                }}
              />
            </ReactCardFlip>
          </UCMobile>

          <UCMobile
            style={{
              position: "relative",
              top: "-3rem",
              left: "40%",
            }}
          >
            <ReactCardFlip isFlipped={isFlipped[2]} flipDirection="vertical">
              <UCItem
                src="images/usecases/uc02_name.png"
                alt="Delegation polygon"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  handleFlip(2);
                }}
                className="reveal"
              />
              <UCItemFlipped
                src="images/usecases/uc02_des.png"
                alt="Delegation polygon description"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  handleFlip(2);
                }}
              />
            </ReactCardFlip>
          </UCMobile>

          <UCMobile
            style={{
              position: "relative",
              marginTop: "-6rem",
            }}
          >
            <ReactCardFlip isFlipped={isFlipped[3]} flipDirection="vertical">
              <UCItem
                src="images/usecases/uc03_name.png"
                alt="VIP polygon"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  handleFlip(3);
                }}
                className="reveal"
              />
              <UCItemFlipped
                src="images/usecases/uc03_des.png"
                alt="VIP polygon description"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  handleFlip(3);
                }}
              />
            </ReactCardFlip>
          </UCMobile>

          <UCMobile
            style={{
              position: "relative",
              marginTop: "-2rem",
              left: "40%",
            }}
          >
            <ReactCardFlip isFlipped={isFlipped[4]} flipDirection="vertical">
              <UCItem
                src="images/usecases/uc04_name.png"
                alt="Guild polygon"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  handleFlip(4);
                }}
                className="reveal"
              />
              <UCItemFlipped
                src="images/usecases/uc04_des.png"
                alt="Guild polygon description"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  handleFlip(4);
                }}
              />
            </ReactCardFlip>
          </UCMobile>

          <UCMobile
            style={{
              position: "relative",
              marginTop: "-4rem",
              marginBottom: "-8rem",
            }}
          >
            <ReactCardFlip isFlipped={isFlipped[5]} flipDirection="vertical">
              <UCItem
                src="images/usecases/uc05_name.png"
                alt="Discord polygon"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  handleFlip(5);
                }}
                className="reveal"
              />
              <UCItemFlipped
                src="images/usecases/uc05_des.png"
                alt="Discord polygon description"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  handleFlip(5);
                }}
              />
            </ReactCardFlip>
          </UCMobile>
        </UCWrapperMobile>
      </Mobile>
    </Wrapper>
  );
}

export default UseCases;
