import { FC } from "react";
import styled from "styled-components";

const Header = styled.div`
  position: relative;
  z-index: 2;
  color: ${(props) => props.theme.colors.text};
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: ${(props) => props.theme.fontSizeElements.prime}px;
  font-weight: bold;
  margin-bottom: 2rem;
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    font-size: ${(props) => props.theme.fontSizeElements.h2}px;
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    font-size: ${(props) => props.theme.fontSizeElements.h4}px;
    margin-bottom: 1rem;
  }
`;

type HeaderProps = {
  sectionText?: string;
};

const SectionHeader: FC<HeaderProps> = ({ sectionText }) => {
  return <Header className="reveal">{sectionText}</Header>;
};

export default SectionHeader;
