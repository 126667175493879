import styled from "styled-components";

const Wrapper = styled.div`
  text-align: left;
`;

const Link = styled.a`
  img {
    max-width: 250px;

    @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
      max-width: 200px;
    }
  }
`;

const Slogan = styled.p`
  font-family: ${(props) => props.theme.fonts.description};
  font-size: ${(props) => props.theme.fontSizeElements.h1phone}px;
  padding-left: 58px;
  margin: 0;

  @media screen and (${(props) => props.theme.breakpoints.lteTabletQuery}) {
    font-size: ${(props) => props.theme.fontSizeElements.h2phone}px;
  }

  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    padding-left: 45px;
  }
`;

interface LogoInterface {
  footer?: boolean;
}

const Logo: React.FC<LogoInterface> = ({ footer = false }) => {
  return (
    <Wrapper>
      <Link href="/">
        <img src="images/logo.svg" alt="Dobby Protocol" />
      </Link>
      {footer && <Slogan>Making NFT Magic</Slogan>}
    </Wrapper>
  );
};

export default Logo;
