import { padding } from "@mui/system";
import styled from "styled-components";
import Button from "./Button/Button";

const PrimeWrapper = styled.div`
  position: relative;
  padding: 5rem 0 16rem;
  margin: 2rem auto -5rem;
  text-align: center;
  &:after {
    content: "";
    background-image: url(./images/background/polygonPrime.png);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.7;
    z-index: 0;
  }
  @media screen and (${(props) => props.theme.breakpoints.desktopQuery}) {
    margin: 2rem auto -10rem;
    padding-top: 2rem;
  }
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    padding: 5rem 0 10rem;
    margin-top: 2rem;
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    margin: 2rem auto;
    padding: 2rem 0 2rem;
    &:after {
    }
  }
`;

const PrimeHeader = styled.header`
  position: relative;
  z-index: 2;
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: ${(props) => props.theme.fontSizeElements.prime}px;
  font-weight: 500;
  margin: 2rem 10% 0;
  background-image: linear-gradient(
    to right,
    #fb0707 30%,
    #ffb22c 60%,
    #fff500 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow-wrap: break-word;
  @media screen and (${(props) => props.theme.breakpoints.desktopQuery}) {
    font-size: ${(props) => props.theme.fontSizeElements.primeTablet}px;
  }
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    font-size: ${(props) => props.theme.fontSizeElements.h1}px;
    margin: -20px 10% 0;
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    font-size: ${(props) => props.theme.fontSizeElements.h3}px;
    margin: -20px 10% 0;
  }
`;

const PrimeDescription = styled.div`
  position: relative;
  z-index: 2;
  width: 40%;
  margin: 2rem auto 2rem;
  line-height: 2;
  font-family: ${(props) => props.theme.fonts.description};
  font-size: ${(props) => props.theme.fontSizeElements.h4}px;
  @media screen and (${(props) => props.theme.breakpoints.desktopQuery}) {
    width: 45%;
    margin: 2rem auto 2rem;
    font-size: ${(props) => props.theme.fontSizeElements.h5}px;
  }
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    width: 45%;
    margin: 1rem auto 2rem;
    font-size: ${(props) => props.theme.fontSizeElements.h6}px;
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    font-size: ${(props) => props.theme.fontSizeElements.h3phone}px;
    width: 50%;
    margin: 1rem auto;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageDobby = styled.img`
  width: 350px;
  padding-top: 90px;
  z-index: 2;
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    width: 150px;
    margin-bottom: 140px;
    margin-left: 10px;
  }
`;

const RightTextWrapper = styled.div`
  width: 900px;
  z-index: 2;
`;

const Buttons = styled.div`
  position: relative;
  z-index: 2;
`;

function PrimeNotFound404() {
  return (
    <>
      <PrimeWrapper>
        <ContentWrapper>
          <ImageDobby src="./images/dobbyImages/404Dobby.png"></ImageDobby>

          <RightTextWrapper>
            <PrimeHeader>
              404
              <br />
              Page Not Found
            </PrimeHeader>
            <PrimeDescription>
              We've explored deep and wide,
              <br />
              but we can't find the page
              <br />
              you are looking for.
            </PrimeDescription>
            <Button variant="filled" size="large" href="https://dobby.pro/">
              Navigate back home
            </Button>
          </RightTextWrapper>
        </ContentWrapper>
      </PrimeWrapper>
    </>
  );
}

export default PrimeNotFound404;
