/* @ts-ignore */
import { slide as Menu } from "react-burger-menu";
import styled, { css } from "styled-components";
import theme from "../theme/theme";
import Discord from "../icons/Discord";
import Twitter from "../icons/Twitter";
import GitHub from "../icons/GitHub";
import Medium from "../icons/Medium";
import LinkedIn from "../icons/LinkedIn";
import { getAnalytics, logEvent } from "firebase/analytics";

type PropsHamburgerMenu = {
  pageWrapId: string;
  outerContainerId: string;
};

var styles = {
  bmBurgerButton: {
    position: "absolute",
    width: "25px",
    height: "20px",
    right: "20px",
    top: "26px",
  },
  bmBurgerBars: {
    height: "10%",
    background: theme.colors.text,
  },
  bmBurgerBarsHover: {
    background: theme.colorsInvert.primary,
  },
  bmCrossButton: {
    width: "30px",
    height: "30px",
  },
  bmCross: {
    height: "30px",
    background: theme.colors.text,
  },
  bmCrossHover: {
    background: theme.colors.background,
  },
  bmMenuWrap: {
    position: "fixed",
    width: "100%",
  },
  bmMenu: {
    background: theme.colors.hamburger,
    paddingTop: "40px",
    paddingBottom: "40px",
    height: "auto",
    textAlign: "center",
    fontSize: theme.fontSizeElements.body2,
  },
  bmMorphShape: {
    fill: theme.colorsInvert.text,
  },
  bmItem: {
    display: "block",
    margin: "1.5em 0",
    textDecoration: "none",
    color: theme.colors.text,
    fontFamily: theme.fonts.description,
    transform: theme.animation.transitionBasic,
    position: "relative",
  },
  bmItemHover: {
    textDecoration: "none",
    color: theme.colors.danger,
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.5)",
  },
};

const Social = styled.div`
  display: flex !important;
  justify-content: center !important;
  gap: 5px;

  ${({ theme }) => css`
    @media screen and (${theme.breakpoints.lteTabletQuery}) {
      margin: 25px 0 15px;
    }
  `}
`;

const Link = styled.a`
  ${({ theme }) => css`
    display: inline-block;
    background: url("images/social/bg.svg") no-repeat center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    margin-top: 1em;

    svg {
      fill: ${theme.colors.text};
    }

    &:hover {
      svg {
        fill: ${theme.colors.danger};
      }
    }

    ${({ theme }) => css`
      @media screen and (${theme.breakpoints.lteTabletQuery}) {
        height: 40px;
        width: 40px;

        svg {
          height: 0.9em;
          width: 0.9em;
        }
      }
    `}
  `}
`;

function HamburgerMenu({ pageWrapId, outerContainerId }: PropsHamburgerMenu) {
  const analytics = getAnalytics();

  function logSocialsClick(media: String) {
    logEvent(analytics, `[Hamburger]Socials: ${media}`);
  }

  return (
    <Menu
      pageWrapId={pageWrapId}
      outerContainerId={outerContainerId}
      styles={styles}
      right
      isOpen={false}
    >
      <a className="menu-item" href="https://dobby.pro/#top-collections">
        Collections
      </a>
      <a className="menu-item" href="https://dobby.pro/#usecases">
        Use Cases
      </a>
      <a className="menu-item" href="https://dobby.pro/#features">
        Features
      </a>
      {/* <span>
        <Button variant="outlined" size="large" disabled>
          Launch App
        </Button>
      </span> */}
      <Social>
        <Link
          href="https://discord.com/invite/ZPEvPDWhXT"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => logSocialsClick("discord")}
        >
          <Discord />
        </Link>
        <Link
          href="https://twitter.com/dobbyprotocol"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => logSocialsClick("discord")}
        >
          <Twitter />
        </Link>
        <Link
          href="https://medium.com/@dobbyprotocol"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => logSocialsClick("medium")}
        >
          <Medium />
        </Link>
        <Link
          href="https://www.linkedin.com/company/dobby-labs/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => logSocialsClick("linkedIn")}
        >
          <LinkedIn />
        </Link>
        <Link
          href="https://github.com/DOBBYLABS"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => logSocialsClick("github")}
        >
          <GitHub />
        </Link>
        {/* <Link href="/">
          <Telegram />
        </Link> */}
      </Social>
    </Menu>
  );
}

export default HamburgerMenu;
