import { LteTablet } from "../utils/MediaQueries";
import Base from "./Base";
import Footer from "./Footer";
import HamburgerMenu from "./HamburgerMenu";
import Navbar from "./Navbar";
import PrimeNotFound404 from "./PrimeNotFound404";
const NotFound404 = () => {
  return (
    <Base id="outer-container">
      <LteTablet>
        <HamburgerMenu
          pageWrapId={"page-wrap"}
          outerContainerId={"outer-container"}
        />
      </LteTablet>
      <main id="page-wrap" style={{ minHeight: "100vh" }}>
        <Navbar />
        <PrimeNotFound404 />
        <div style={{ margin: "auto" }}></div>
        <Footer />
      </main>
    </Base>
  );
};

export default NotFound404;
