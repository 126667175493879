const theme = {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
    mobile: 767,
    tablet: 768,
    desktop: 992,
    desktopLg: 1400,
    mobileQuery: "max-width: 767px",
    tabletQuery: "min-width: 768px) and (max-width: 991px",
    gteTabletQuery: "min-width: 768px",
    lteTabletQuery: "max-width: 991px",
    tabletAndDesktopQuery: "min-width: 768px) and (max-width: 1399px",
    desktopQuery: "min-width: 992px) and (max-width: 1399px",
    gteDesktopQuery: "min-width: 992px",
    lteDesktopQuery: "max-width: 1399px",
    desktopLgQuery: "min-width: 1400px",
    desktopxLgQuery: "min-width: 1700px",
  },
  layouts: {
    lg: 1200, // 1366
    xl: 1400, // 1536
  },
  fonts: {
    body: "Clash Display",
    heading: "Clash Display",
    description: "Poppins",
  },
  fontSizes: [14, 16, 18, 20, 24, 28, 32, 40],
  fontSizeElements: {
    prime: 80,
    primeTablet: 64,
    h1: 48,
    h2: 36,
    h3: 28,
    h4: 22,
    h5: 18,
    h6: 16,
    subtitle1: 28,
    subtitle2: 22,
    body1: 20,
    body2: 18,
    button: 16,
    caption: 14,
    h1phone: 14,
    h2phone: 12,
    h3phone: 10,
    buttonPhone: 8,
  },
  colors: {
    text: "#ffffff",
    subtitle: "#bebebe",
    border: "#ffffff",
    background: "#000000",
    primary: "#ffb22c",
    primaryActive: "#fff500",
    danger: "#e42528",
    hamburger: "#1d1d1d",
  },
  colorsInvert: {
    text: "#000000",
    subtitle: "#bebebe",
    border: "#000000",
    background: "#ffffff",
    primary: "#e42528",
    primaryActive: "#fff500",
    danger: "#ffb22c",
  },
  animation: {
    transitionBasic: "all 0.3s ease",
  },
};

export default theme;
