import styled, { css } from "styled-components";
import SectionHeader from "./SectionHeader";
import SectionWrapper from "./SectionWrapper";
import Slider from "react-slick";
import SliderArrow from "./SliderArrow";
import Button from "./Button/Button";

const Description = styled.text`
  ${({ theme }) => css`
    font-family: ${theme.fonts.description};
    font-size: ${theme.fontSizeElements.body2}px;
    margin-bottom: 30px;
    @media screen and (${theme.breakpoints.tabletQuery}) {
      font-size: ${theme.fontSizeElements.body2}px;
    }
    @media screen and (${theme.breakpoints.mobileQuery}) {
      font-size: ${theme.fontSizeElements.h3phone}px;
    }
  `}
`;

const SliderWrapper = styled.div`
  ${({ theme }) => css`
    max-width: 1440px;
    width: 100%;
    margin: 0 auto 50px;

    @media screen and (${theme.breakpoints.mobileQuery}) {
      margin: 0 auto 25px;
    }

    .slider-container {
      margin: 0 60px;

      @media screen and (${theme.breakpoints.mobileQuery}) {
        margin: 0 20px;
      }
    }
  `}
`;

const Card = styled.a`
  display: block;
  padding: 20px;
  margin: 12px;
  border-radius: 20px;
  border: 1px solid #403a3a;
  transition: all 0.1s ease-in-out;
  color: white;
  text-decoration: none;
  &:hover {
    background: linear-gradient(black, black) padding-box,
      linear-gradient(to right, #ff291c, #fff500) border-box;
    border: 2px solid transparent;
    margin: 2px;
  }
`;

const Title = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.fonts.description};
    font-size: ${theme.fontSizeElements.body1}px;
    font-weight: 600;
    margin-bottom: 0;

    @media screen and (${theme.breakpoints.mobileQuery}) {
      font-size: ${theme.fontSizeElements.h2phone}px;
    }
  `}
`;

const ImageWrapper = styled.div`
  img {
    transition: width 0.2s ease-in-out;
    width: 100%;
    margin: 0 auto;
    border-radius: 15px;
  }
`;

const Buttons = styled.div`
  text-align: center;
  margin-bottom: 50px;

  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    margin-bottom: 25px;
  }
`;

function TopCollections() {
  const collection = [
    {
      name: "ENS",
      image: "./images/topCollections/ens.jpg",
      link: "https://ens.domains/",
    },
    {
      name: "Decentraland",
      image: "./images/topCollections/decentraland.jpg",
      link: "https://decentraland.org/",
    },
    {
      name: "Warena",
      image: "./images/topCollections/warena.jpg",
      link: "https://play.warena.io/",
    },
  ];

  const sliderSettings = {
    nextArrow: <SliderArrow direction="next" />,
    prevArrow: <SliderArrow direction="prev" />,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: "slider-container",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <SectionWrapper id="top-collections" style={{ scrollMarginTop: "50px" }}>
      <SectionHeader sectionText="Top Collections" />
      <Description className="reveal">
        Find the most popular NFT collections with collateral-free renting.
        Following collections will be supported at launch.
      </Description>
      <SliderWrapper>
        <Slider {...sliderSettings}>
          {collection.map((item, index) => {
            return (
              <div key={index}>
                <Card
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={"reveal reveal-" + index}
                >
                  <ImageWrapper>
                    <img src={item.image} alt={item.name} />
                  </ImageWrapper>
                  <Title>{item.name}</Title>
                </Card>
              </div>
            );
          })}
        </Slider>
      </SliderWrapper>
      {/* <Buttons>
        <Button variant="outlined" size="large">
          See more
        </Button>
      </Buttons> */}
    </SectionWrapper>
  );
}

export default TopCollections;
