import firebase from "firebase/compat/app";
import getAuth from "firebase/compat/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const {
  initializeAppCheck,
  ReCaptchaV3Provider,
} = require("firebase/app-check");

declare global {
  // var must be used for global scopes
  // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-4.html#type-checking-for-globalthis
  // eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyAf83oXSitgmZw21gUsvCduDRUqfh-u7Zk",

  authDomain: "dobbyprotocol-ee906.firebaseapp.com",

  projectId: "dobbyprotocol-ee906",

  storageBucket: "dobbyprotocol-ee906.appspot.com",

  messagingSenderId: "17943107644",

  appId: "1:17943107644:web:182a6a2e9f0fc8a54f0b39",
};

const app = firebase.initializeApp(firebaseConfig);

// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(app);

//self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;

// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider(process.env.REACT_APP_SECRET_TOKEN),
// });
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_SECRET_TOKEN),
});

const db = getFirestore(app);

export default db;
