import React from "react";
import IconBase from "react-icon-base";

export default function Icon(props: any) {
  return (
    <IconBase
      {...props}
      viewBox={"0 0 75 75"}
      dangerouslySetInnerHTML={{
        __html: `
        <path d="M19.709 18H59.2936V58H19.709V18Z" fill="white"/>
        <path d="M38.8067 2L39.8365 2.01358C59.6783 2.53764 75.6135 18.1599 75.6135 37.3333C75.6135 56.802 59.124 72.6667 38.8067 72.6667C18.5262 72.6667 2 56.802 2 37.3333C2 17.8293 18.5262 2 38.8067 2ZM46.0945 23.2C44.9903 22.14 43.2603 22.14 42.193 23.2L29.3474 35.4607C28.8321 35.9553 28.5377 36.6267 28.5377 37.3333C28.5377 38.04 28.8321 38.7113 29.3474 39.206L42.193 51.4667C42.7082 51.9967 43.4076 52.244 44.1069 52.244C44.843 52.244 45.5424 51.9967 46.0945 51.4667C47.1619 50.4067 47.1619 48.746 46.0577 47.7213L35.1997 37.3333L46.0577 26.9453C47.1619 25.9207 47.1619 24.2247 46.0945 23.2Z" fill="#272626"/>
        <path d="M38.816 1.30006L38.816 1.3H38.8067C18.1673 1.3 1.3 17.4156 1.3 37.3333C1.3 57.2153 18.1668 73.3667 38.8067 73.3667C59.4839 73.3667 76.3135 57.2148 76.3135 37.3333C76.3135 17.7538 60.0488 1.84718 39.8549 1.31383V1.31377L39.8457 1.31364L38.816 1.30006ZM42.6763 23.7064L42.6764 23.7065L42.6862 23.6967C43.4761 22.9123 44.7717 22.9005 45.6097 23.705C46.3884 24.4525 46.389 25.6829 45.5815 26.4322L45.5815 26.4322L45.5738 26.4395L34.7158 36.8275L34.1871 37.3333L34.7158 37.8391L45.5738 48.2271L45.5737 48.2272L45.5815 48.2344C46.3828 48.978 46.396 50.1764 45.6054 50.9658C45.1972 51.3555 44.679 51.544 44.1069 51.544C43.5694 51.544 43.0605 51.3548 42.6948 50.9787L42.6857 50.9693L42.6763 50.9603L29.8322 38.701C29.832 38.7008 29.8318 38.7007 29.8316 38.7005C29.4493 38.3332 29.2377 37.8425 29.2377 37.3333C29.2377 36.8242 29.4493 36.3334 29.8316 35.9662C29.8318 35.966 29.832 35.9658 29.8322 35.9656L42.6763 23.7064Z" stroke="white" stroke-opacity="0.24" stroke-width="1.4"/>        
      `,
      }}
    />
  );
}
