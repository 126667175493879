export const content = {
  features: [
    {
      header: "NFT stays in the owner's wallet",
      des: "The ownership is retained for the entire duration of the loan and listing. So no need to trust third parties with staking of the NFT into their contracts.",
      img: "./images/features/02.png",
    },
    {
      header: "Claiming owner benefits during the rental period",
      des: "If you lend the NFT, you are still entitled to receive the rewards available to the owner, such as airdrops or free minting. You can also utilize the services provided to owners.",
      img: "./images/features/01.png",
    },
    // {
    //   header: "Sale of NFT without cancellation of lease",
    //   des: "The user retains user rights to the NFT even if the owner decides to sell the NFT. It is guaranteed that until the time period expires or the cancellation of the contract is agreed, the user's rights remain after the sale of the NFT until the expiry of the pre-agreed time period.",
    //   img: "./images/features/01.png",
    // },
    {
      header: "Unlimited listing opportunities",
      des: "Because the NFT remains in the owner's wallet at all times, it is possible to have multiple active sale and rent listings at the same time. You can list for sale even if renting is active!",
      img: "./images/features/05.png",
    },
    {
      header: "Protection of the user",
      des: "It is not possible to cancel the NFT usership until the predetermined period of time has passed or both parties agreed to terminate the loan. Your status remains even if the NFT is sold!",
      img: "./images/features/06.png",
    },
    {
      header: "Improved security",
      des: "The owner can assign a hot wallet as the user. This allows them to interact with DApps without the fear of losing the NFT (the user does not have transfer rights).",
      img: "./images/features/04.png",
    },
    // {
    //   header: "Ownership use of NFT during leding period",
    //   des: "Even if the NFT is leased, it is still possible to use the ownership rights to the NFT",
    //   img: "./images/features/07.png",
    // },
    {
      header: "No Collateral Required",
      des: "With the addition of the borrowable user role, there is no longer the need for any active collateral.",
      img: "./images/features/08.png",
    },
    // {
    //   header: "NFT listed for sale as well as lend",
    //   des: "Because the NFT remains in the owner's wallet at all times, it is possible to have the NFT listed for both loan and sale.",
    //   img: "./images/features/09.png",
    // },
  ],
};
