import styled from "styled-components";
import { GteDesktop } from "../utils/MediaQueries";
import Logo from "./Logo";
import Tooltip from "@mui/material/Tooltip";

const Topbar = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-left: 20px;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  color: ${(props) => props.theme.colorsInvert.text};
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    color: green;
  }
`;

const Menu = styled.div`
  position: absolute;
  right: 0;
  margin-right: 50px;
  display: flex;
  align-items: center;
`;

const MenuItem = styled.a`
  margin-left: 30px;
  text-decoration: none;
  font-family: ${(props) => props.theme.fonts.description};
  font-size: ${(props) => props.theme.fontSizeElements.body2}px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.text};
  transition: ${(props) => props.theme.animation.transitionBasic};
  &:hover {
    color: ${(props) => props.theme.colors.danger};
  }
`;

const LaunchAppMockButton = styled.div`
  font-family: ${(props) => props.theme.fonts.description};
  font-size: ${(props) => props.theme.fontSizeElements.button}px;
  font-weight: 700;
  cursor: not-allowed;
  border-radius: 30px;
  background: transparent;
  margin: 0 1.5%;
  line-height: 40px;
  width: 130%;
  height: 40px !important;
  text-align: center;
  filter: brightness(40%);
  background-image: linear-gradient(
    to right,
    #fb0707 0%,
    #ffb22c 80%,
    #fff500 100%
  );
  &:hover {
    color: ${(props) => props.theme.colorsInvert.text};
  }
`;

function Navbar() {
  return (
    <Topbar>
      <Logo />
      <GteDesktop>
        <>
          <Menu>
            <MenuItem href="https://dobby.pro/#top-collections">
              Collections
            </MenuItem>
            <MenuItem href="https://dobby.pro/#usecases">Use Cases</MenuItem>
            <MenuItem href="https://dobby.pro/#features">Features</MenuItem>
            <MenuItem>
              <Tooltip title="Coming soon!" arrow>
                <LaunchAppMockButton>Launch App</LaunchAppMockButton>
              </Tooltip>
            </MenuItem>
          </Menu>
        </>
      </GteDesktop>
    </Topbar>
  );
}

export default Navbar;
