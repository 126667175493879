import React from "react";
import IconBase from "react-icon-base";

export default function Icon(props: any) {
  return (
    <IconBase
      {...props}
      viewBox={"0 0 75 75"}
      dangerouslySetInnerHTML={{
        __html: `
        <path d="M57.9045 18H18.3199V58H57.9045V18Z" fill="white"/>
        <path d="M38.8068 2L37.7771 2.01358C17.9353 2.53764 2.00006 18.1599 2.00006 37.3333C2.00006 56.802 18.4895 72.6667 38.8068 72.6667C59.0873 72.6667 75.6135 56.802 75.6135 37.3333C75.6135 17.8293 59.0873 2 38.8068 2ZM31.5191 23.2C32.6233 22.14 34.3532 22.14 35.4206 23.2L48.2661 35.4607C48.7814 35.9553 49.0759 36.6267 49.0759 37.3333C49.0759 38.04 48.7814 38.7113 48.2661 39.206L35.4206 51.4667C34.9053 51.9967 34.206 52.244 33.5066 52.244C32.7705 52.244 32.0712 51.9967 31.5191 51.4667C30.4517 50.4067 30.4517 48.746 31.5559 47.7213L42.4138 37.3333L31.5559 26.9453C30.4517 25.9207 30.4517 24.2247 31.5191 23.2Z" fill="#272626"/>
        <path d="M38.7976 1.30006L38.7976 1.3H38.8068C59.4462 1.3 76.3135 17.4156 76.3135 37.3333C76.3135 57.2153 59.4467 73.3667 38.8068 73.3667C18.1296 73.3667 1.30006 57.2148 1.30006 37.3333C1.30006 17.7538 17.5648 1.84718 37.7586 1.31383V1.31377L37.7678 1.31364L38.7976 1.30006ZM34.9373 23.7064L34.9372 23.7065L34.9273 23.6967C34.1374 22.9123 32.8418 22.9005 32.0038 23.705C31.2251 24.4525 31.2245 25.6829 32.032 26.4322L32.0321 26.4322L32.0398 26.4395L42.8978 36.8275L43.4264 37.3333L42.8978 37.8391L32.0398 48.2271L32.0398 48.2272L32.032 48.2344C31.2308 48.978 31.2175 50.1764 32.0081 50.9658C32.4164 51.3555 32.9345 51.544 33.5066 51.544C34.0441 51.544 34.553 51.3548 34.9187 50.9787L34.9278 50.9693L34.9373 50.9603L47.7814 38.701C47.7815 38.7008 47.7817 38.7007 47.7819 38.7005C48.1642 38.3332 48.3759 37.8425 48.3759 37.3333C48.3759 36.8242 48.1642 36.3334 47.7819 35.9662C47.7817 35.966 47.7815 35.9658 47.7814 35.9656L34.9373 23.7064Z" stroke="white" stroke-opacity="0.24" stroke-width="1.4"/>
      `,
      }}
    />
  );
}
