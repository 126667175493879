import SectionHeader from "./SectionHeader";
import SectionWrapper from "./SectionWrapper";
import styled from "styled-components";

const PartnersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;

  @media screen and (${(props) => props.theme.breakpoints.lteTabletQuery}) {
    grid-template-columns: repeat(2, 3fr);
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    margin-top: 20px;
  }
`;

const Partner = styled.div`
  padding: 20px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 70%;
    max-height: 200px;
  }
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    img {
      max-width: 80%;
    }
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    img {
      max-width: 100%;
    }
  }
`;

const Partners = () => {
  return (
    <SectionWrapper>
      <SectionHeader sectionText="Partners" />
      <PartnersWrapper>
        <Partner className="reveal reveal-0">
          <img src="images/partners/dobby.png" alt="dobby labs" />
        </Partner>
        <Partner className="reveal reveal-1">
          <img src="images/partners/rbf.svg" alt="rockaway" />
        </Partner>
        {/* <Partner   >
          <img src="images/partners/heavenland.png" alt="heavenland" />
        </Partner> */}
        <Partner className="reveal reveal-2">
          <img src="images/partners/tokenVentures.svg" alt="token ventures" />
        </Partner>
        <Partner className="reveal reveal-6">
          <img src="images/partners/utblogo.svg" alt="utb" />
        </Partner>
        {/* <Partner   >
          <img src="images/partners/logo2.png" alt="dobby labs" />
        </Partner> */}
      </PartnersWrapper>
    </SectionWrapper>
  );
};

export default Partners;
