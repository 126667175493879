import styled, { css } from "styled-components";
import { transitions2d } from "react-hover-css";

export const Wrapper = styled.button<{
  block?: boolean;
  color?: "primary" | "secondary";
  disabled?: boolean;
  variant?: "filled" | "outlined" | "transparent";
  gradient?: boolean;
}>`
  ${({ theme, block, color, variant, gradient }) => css`
    font-family: ${theme.fonts.description};
    font-size: ${theme.fontSizeElements.button}px;
    font-weight: 700;
    align-items: center;
    cursor: pointer;
    border: 0;
    border-radius: 30px;
    display: inline-flex;
    justify-content: center;
    position: relative;
    margin: 0 1.5%;
    ${transitions2d.hvrPulseGrow};

    ${color === "primary" &&
    css`
      color: ${theme.colors.text};
    `}

    ${color === "secondary" &&
    css`
      color: ${theme.colorsInvert.text};
    `}

    ${variant === "outlined" &&
    css`
      background: ${theme.colors.background};
      border-width: 2px;
      border-style: solid;

      ${gradient &&
      css`
        background: linear-gradient(black, black) padding-box,
          linear-gradient(to right, #fb0707, #fff500) border-box;
        border-color: transparent;
        &:hover {
          color: ${(props) => props.theme.colors.danger};
        }
      `}
    `}

    ${variant === "filled" &&
    css`
      ${gradient &&
      css`
        background-image: linear-gradient(
          to right,
          #fb0707 0%,
          #ffb22c 80%,
          #fff500 100%
        );
      `}
      &:hover {
        color: ${(props) => props.theme.colorsInvert.text};
      }
    `}

    ${block &&
    css`
      width: 100%;
    `}

    &:disabled {
      background: linear-gradient(#4a262d, #752f3c, #90283b);
      color: ${theme.colors.text};
      cursor: not-allowed;
      margin: 0;
    }

    transition: ${(props) => props.theme.animation.transitionBasic};
  `}
`;

export const Link = styled.a`
  text-decoration: none;
`;

export const Label = styled.span<{
  size?: "small" | "medium" | "large";
}>`
  text-decoration: none;

  ${({ theme, size }) => css`
    display: flex;

    ${size === "small" &&
    css`
      font-size: 12px;
      padding: 4px 12px;
    `}
    ${size === "medium" &&
    css`
      font-size: 14px;
      padding: 4px 20px;

      @media screen and (${theme.breakpoints.mobileQuery}) {
        font-size: 10px;
        padding: 1px 10px;
      }
    `}
    ${size === "large" &&
    css`
      font-size: 18px;
      padding: 5px 25px;

      @media screen and (${theme.breakpoints.mobileQuery}) {
        font-size: 12px;
        padding: 4px 20px;
      }
    `}
  `}
`;
