import SectionHeader from "./SectionHeader";
import SectionWrapper from "./SectionWrapper";
import styled from "styled-components";
import { CardContent } from "@mui/material";
import { content } from "../contents/content";

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1100px;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  font-family: ${(props) => props.theme.fonts.body};
  font-size: ${(props) => props.theme.fontSizeElements.body2}px;
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    font-size: ${(props) => props.theme.fontSizeElements.body2}px;
    width: 90%;
    margin: 20px auto;
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    font-size: ${(props) => props.theme.fontSizeElements.h1phone}px;
    margin: 30px auto 0;
  }
`;

interface FeatureCardProps {
  key: Number;
}

const FeatureCard = styled.div<FeatureCardProps>`
  position: relative;
  min-width: 275px;
  width: 29%;
  margin: 5rem 1.5%;
  border: solid rgb(210, 210, 210, 0.15) 2px;
  border-radius: 15px;
  background-color: rgb(210, 210, 210, 0.1);
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    margin: 5rem 2% 3rem;
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    margin: 2rem 1% 3rem;
  }
`;

const Icon = styled.img`
  position: relative;
  top: -9rem;
  width: 45%;
  margin: 0 auto -200px;
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    width: 55%;
  }
`;

const FeatureHeader = styled(CardContent)`
  margin: 10% 2% 0;
  height: 20%;
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: ${(props) => props.theme.fontSizeElements.subtitle1}px;
  font-weight: bold;
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    height: 15%;
    font-size: ${(props) => props.theme.fontSizeElements.body2}px;
  }
`;

const FeatureCardContent = styled(CardContent)`
  height: 20%;
  margin: 0 1rem;
  padding-bottom: 4rem !important;
`;

function Features() {
  return (
    <SectionWrapper id="features">
      <SectionHeader sectionText="Features" />
      <CardsWrapper>
        {content.features.map((item, index) => (
          <FeatureCard key={index} className={"reveal reveal-" + index}>
            <Icon src={item.img} />
            <FeatureHeader>{item.header}</FeatureHeader>
            <FeatureCardContent>{item.des}</FeatureCardContent>
          </FeatureCard>
        ))}
      </CardsWrapper>
    </SectionWrapper>
  );
}

export default Features;
