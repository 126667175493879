import styled from "styled-components";

const SectionWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  text-align: center;
  margin: 100px auto 0;
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    width: 90%;
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    margin: 50px auto 0;
    width: 80%;
  }
`;

export default SectionWrapper;
