import { IconButton, InputAdornment, TextField } from "@mui/material";
import styled from "styled-components";
import Button from "./Button/Button";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  deleteDoc,
} from "firebase/firestore";
import db from "../utils/Firebase";
import { Mobile, TabletDesktop } from "../utils/MediaQueries";
import { getAnalytics, logEvent } from "firebase/analytics";

const SearchBoxDiv = styled.div`
  width: 40%;
  margin: 0 auto;
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    width: 70%;
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    width: 90%;
  }
`;

const SearchBox = styled(TextField)(() => ({
  width: "100%",
  background: "#D9D9D9",
  borderRadius: "40px",
  border: "none",
  "& input": {
    paddingLeft: "30px",
  },
  "& fieldset": {
    borderRadius: "30px",
  },
}));

const PrimeDescription = styled.div`
  position: relative;
  z-index: 2;
  width: 40%;
  text-align: center;
  margin: 0rem auto 0rem;
  font-family: ${(props) => props.theme.fonts.description};
  font-size: ${(props) => props.theme.fontSizeElements.h4}px;
  @media screen and (${(props) => props.theme.breakpoints.desktopQuery}) {
    width: 45%;
    margin: 2rem auto 2rem;
    font-size: ${(props) => props.theme.fontSizeElements.h5}px;
  }
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    width: 45%;
    margin: 1rem auto 2rem;
    font-size: ${(props) => props.theme.fontSizeElements.h6}px;
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    font-size: ${(props) => props.theme.fontSizeElements.h3phone}px;
    width: 50%;
    margin: 1rem auto;
  }
`;

interface UnsubscribeFormInterface {
  setFormShow: Dispatch<SetStateAction<boolean>>;
  formShow: boolean;
}

const UnsubForm = ({ formShow, setFormShow }: UnsubscribeFormInterface) => {
  const analytics = getAnalytics();

  const form = useRef<any>();
  //const [formShow, setFormShow] = useState(true);
  const unsub = async (event: any) => {
    event.preventDefault();
    setFormShow(false);

    const q = query(
      collection(db, "Emails"),
      where("email", "==", event.target[0].value)
    );

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      setFormShow(false);
      return;
    }

    querySnapshot.forEach((email) => {
      console.log(email.id, " => ", email.data());
      const docRef = doc(db, "Emails", email.id);
      deleteDoc(docRef)
        .then(() => setFormShow(false))
        .catch((error) => console.log(error.message));
    });

    logEvent(analytics, "unsubscribed");
  };
  return (
    <>
      <TabletDesktop>
        {formShow ? (
          <SearchBoxDiv>
            <form ref={form} onSubmit={unsub}>
              <SearchBox
                style={{ margin: "0 auto 50px" }}
                placeholder="Your email..."
                name="user_email"
                type="email"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" color="primary">
                        <Button type="submit" size="large">
                          Unsubscribe
                        </Button>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              ></SearchBox>
            </form>
          </SearchBoxDiv>
        ) : (
          <PrimeDescription>
            <img src="./images/dobbyImages/UnsubscribeDobby.png"></img>
          </PrimeDescription>
        )}
      </TabletDesktop>
      <Mobile>
        {formShow ? (
          <SearchBoxDiv>
            <form ref={form} onSubmit={unsub}>
              <SearchBox
                size="small"
                style={{ margin: "0 auto 50px", fontSize: "5" }}
                placeholder="Your email..."
                name="user_email"
                type="email"
                required
                InputProps={{
                  style: { fontSize: 12 },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" color="primary">
                        <Button type="submit" size="small">
                          Unsubscribe
                        </Button>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              ></SearchBox>
            </form>
          </SearchBoxDiv>
        ) : (
          <PrimeDescription>
            <img
              style={{ width: 135 }}
              src="./images/dobbyImages/UnsubscribeDobby.png"
            ></img>
          </PrimeDescription>
        )}
      </Mobile>
    </>
  );
};

export default UnsubForm;
