import styled from "styled-components";
import { CSSProperties } from "react";
import ArrowRight from "../icons/ArrowRight";
import ArrowLeft from "../icons/ArrowLeft";

type Props = {
  style?: CSSProperties | undefined;
  className?: (string | string) | undefined;
  direction?: "prev" | "next";
  onClick?: (() => void) | undefined;
};

const Wrapper = styled.div`
  width: 50px;
  height: 48px;

  &:before {
    content: "";
  }

  svg {
    width: 100%;
    height: 100%;
  }

  &.slick-prev {
    left: -50px;
  }

  &.slick-next {
    right: -50px;
  }
`;

function SliderArrow({ style, className, onClick, direction }: Props) {
  return (
    <Wrapper onClick={onClick} style={style} className={className}>
      {direction === "next" ? <ArrowRight /> : <ArrowLeft />}
    </Wrapper>
  );
}

export default SliderArrow;
